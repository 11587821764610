import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, InputNumber, Popconfirm, Select, Tabs, Upload } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { DatePicker, FormBase, fileValidator, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

import BannerHistory from './BannerHistory';

const FormItem = Form.Item;
const { Option } = Select;
const { TabPane } = Tabs;

class BannerForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      cmsBanners: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    removeBanner: PropTypes.func
  }

  static defaultProps = {
    match: {},
    removeBanner: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  removeBannerImage = (type) => {
    this.formRef.current.setFields([{
      name: type,
      value: "delete",
    }]);
  }

  showImage = (form, type, banner) => {
    const imageField = form.getFieldValue(type);
    return imageField !== "delete" && imageField === undefined && banner[type];
  }

  render() {
    const { match, viewer } = this.props;
    const banner = get(viewer, 'cmsBanners.edges[0].node', {});

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        {this.props.removeBanner && (
        <Popconfirm
          title="Are you sure to delete this?"
          onConfirm={() => { this.props.removeBanner(banner); }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="danger" htmlType="button" disabled={this.shouldDisableBtn()}>Delete</Button>
        </Popconfirm>
        )}

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={banner.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Title"
              name="title"
              rules={[{ required: true, message: 'required' }]}
              initialValue={banner.title}
            >
              <Input placeholder="Title" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Link"
              name="link"
              rules={[{ type: 'url', message: 'invalid URL' }]}
              initialValue={banner.link}
            >
              <Input placeholder="URL" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Show Only On URL Paths"
              name="urlPaths"
              rules={[{ required: false, message: 'required' }]}
              initialValue={get(banner, 'urlPaths', [])}
              extra={
                <>
                  <div>If present, category banners will only show on pages with matching url pathnames.</div>
                  <div>
                    <b>/category/by-brand/flex</b> matches both <b>/category/by-brand/flex</b> and <b>/category/by-brand/flextool</b>. To match exactly, you need to terminate it with a <b>$</b>, i.e. <b>/category/by-brand/flex$</b>
                  </div>
                </>
              }
            >
              <Select
                placeholder="Categories"
                mode="tags"
                tokenSeparators={[',', ' ']}
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={banner.startDate ? moment(banner.startDate) : null}
            >
              <DatePicker showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} placeholder="Start Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={banner.endDate ? moment(banner.endDate) : null}
            >
              <DatePicker showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }} placeholder="End Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Home Banner Big"
              required
            >
              <FormItem
                name="homeBig"
                rules={[
                  { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1)}
                ]}
                valuePropName="file"
              >
                <Upload
                  accept="image/gif,image/png,image/jpeg"
                  beforeUpload={() => false}
                  customRequest={() => {}}
                >
                  <Button>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </FormItem>
              <FormItem noStyle shouldUpdate>
                {(form) => this.showImage(form, 'homeBig', banner) ? (
                  <span>
                    <img alt="" width="200" src={banner.homeBig} />
                    <Button
                      onClick={() => this.removeBannerImage('homeBig')}
                    >
                      <DeleteOutlined />
                    </Button>
                  </span>
                ) : null}
              </FormItem>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Home Banner Small"
              required
            >
              <FormItem
                name="homeSmall"
                rules={[
                  { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1)}
                ]}
                valuePropName="file"
              >
                <Upload
                  accept="image/*"
                  beforeUpload={() => false}
                  customRequest={() => {}}
                >
                  <Button>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </FormItem>
              <FormItem noStyle shouldUpdate>
                {(form) => this.showImage(form, 'homeSmall', banner) ? (
                  <span>
                    <img alt="" width="200" src={banner.homeSmall} />
                    <Button
                      onClick={() => this.removeBannerImage('homeSmall')}
                    >
                      <DeleteOutlined />
                    </Button>
                  </span>
                ) : null}
              </FormItem>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Category Banner Big"
              required
            >
              <FormItem
                name="categoryBig"
                rules={[
                  { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1) }
                ]}
                valuePropName="file"
              >
                <Upload
                  accept="image/*"
                  beforeUpload={() => false}
                  customRequest={() => { }}
                >
                  <Button>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </FormItem>
              <FormItem noStyle shouldUpdate>
                {(form) => this.showImage(form, 'categoryBig', banner) ? (
                  <span>
                    <img alt="" width="200" src={banner.categoryBig} />
                    <Button
                      onClick={() => this.removeBannerImage('categoryBig')}
                    >
                      <DeleteOutlined />
                    </Button>
                  </span>
                ) : null}
              </FormItem>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Category Banner Small"
              required
            >
              <FormItem
                name="categorySmall"
                rules={[
                  { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1) }
                ]}
                valuePropName="file"
              >
                <Upload
                  accept="image/*"
                  beforeUpload={() => false}
                  customRequest={() => {}}
                >
                  <Button>
                    <UploadOutlined /> Upload
                  </Button>
                </Upload>
              </FormItem>
              <FormItem noStyle shouldUpdate>
                {(form) => this.showImage(form, 'categorySmall', banner) ? (
                  <span>
                    <img alt="" width="200" src={banner.categorySmall} />
                    <Button
                      onClick={() => this.removeBannerImage('categorySmall')}
                    >
                      <DeleteOutlined />
                    </Button>
                  </span>
                ) : null}
              </FormItem>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Position"
              name="position"
              initialValue={banner.position}
              extra="Ordering Position, Higher Numbers Appear First"
            >
              <InputNumber placholder="Number" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(banner, 'status') ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>

          {banner.id && (
            <TabPane tab="History" key="history">
              <BannerHistory banner={banner} viewer={viewer} />
            </TabPane>
          )}

        </Tabs>
      </Form>
    );
  }
}
export default BannerForm;
