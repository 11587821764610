import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Affix, Button, Form, Input, InputNumber, Popconfirm, Select, Tabs, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DatePicker, FormBase, fileValidator, formItemLayout } from '~/components/form';
import Presence from '~/components/Presence';

import CarouselImageHistory from './CarouselImageHistory';

const FormItem = Form.Item;
const { Option } = Select;
const { TabPane } = Tabs;

class CarouselImageForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      cmsCarouselImages: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    removeCarousel: PropTypes.func,
  }

  static defaultProps = {
    match: {},
    removeCarousel: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { match, viewer } = this.props;
    const carousel = get(viewer, 'cmsCarouselImages.edges[0].node', {});

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()}>Save</Button>
          </div>
        </Affix>

        {this.props.removeCarousel && (
        <Popconfirm
          title="Are you sure to delete this?"
          onConfirm={() => { this.props.removeCarousel(carousel); }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="danger" htmlType="button" disabled={this.shouldDisableBtn()}>Delete</Button>
        </Popconfirm>
        )}

        <Tabs defaultActiveKey="general">
          <TabPane tab="General" key="general">
            <FormItem
              name="id"
              initialValue={carousel.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Title"
              name="title"
              rules={[{ required: true, message: 'required' }]}
              initialValue={carousel.title}
            >
              <Input placeholder="Title" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Link"
              name="link"
              initialValue={carousel.link}
            >
              <Input placeholder="URL" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={carousel.startDate ? moment(carousel.startDate) : null}
            >
              <DatePicker showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} placeholder="Start Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'required' }]}
              initialValue={carousel.endDate ? moment(carousel.endDate) : null}
            >
              <DatePicker showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }} placeholder="End Date" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Main Image"
              name="main1"
              rules={[
                { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1)}
              ]}
              valuePropName="file"
            >
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                customRequest={() => {}}
              >
                <Button>
                  <UploadOutlined /> Upload
                </Button>
                {carousel.main1 && <img alt="" width="200" src={carousel.main1} />}
              </Upload>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Main Image Small"
              name="main2"
              rules={[
                { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1)}
              ]}
              valuePropName="file"
            >
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                customRequest={() => {}}
              >
                <Button>
                  <UploadOutlined /> Upload
                </Button>
                {carousel.main2 && <img alt="" width="200" src={carousel.main2} />}
              </Upload>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Background Top"
              name="bg1"
              rules={[
                { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1)}
              ]}
              valuePropName="file"
            >
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                customRequest={() => {}}
              >
                <Button>
                  <UploadOutlined /> Upload
                </Button>
                {carousel.bg1 && <img alt="" width="200" src={carousel.bg1} />}
              </Upload>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Background Bottom"
              name="bg2"
              rules={[
                { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1)}
              ]}
              valuePropName="file"
            >
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                customRequest={() => {}}
              >
                <Button>
                  <UploadOutlined /> Upload
                </Button>
                {carousel.bg2 && <img alt="" width="200" src={carousel.bg2} />}
              </Upload>
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Position"
              name="position"
              initialValue={carousel.position}
              extra="Ordering Position, Higher Numbers Appear First"
            >
              <InputNumber placholder="Number" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              name="status"
              rules={[{ required: true, message: 'required' }]}
              initialValue={get(carousel, 'status') ? 1 : 0}
            >
              <Select placeholder="Status">
                <Option value={1}>Enabled</Option>
                <Option value={0}>Disabled</Option>
              </Select>
            </FormItem>
          </TabPane>

          {carousel.id && (
            <TabPane tab="History" key="history">
              <CarouselImageHistory carousel={carousel} viewer={viewer} />
            </TabPane>
          )}

        </Tabs>
      </Form>
    );
  }
}
export default CarouselImageForm;
