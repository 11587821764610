import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { message } from 'antd';
import { RemoveAdminUserMutation, UpdateAdminUserMutation } from './mutations';

import UserForm from './UserForm';

class UserView extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
    }).isRequired,
    viewer: PropTypes.shape({
      adminUsers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  handleSubmit = (form, values) => {
    values.status = !!values.status;
    values.remoteAccess = !!values.remoteAccess;
    values.roles = values.roles.map(p => p.key)
    values.suppliers = values.suppliers ? values.suppliers.map((s) => s.key) : [];
    values.importProducts = values.importProducts || false;
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      safeGuard: "update",
      onCompleted: () => {
        message.success('Saved');
        form.resetFields();
      },
    };
    UpdateAdminUserMutation.commit(mutation);
  }

  remove = (user) => {
    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: { id: user.id } },
      viewer: this.props.viewer,
      safeGuard: "delete",
      onCompleted: () => {
        this.props.router.push('/user');
      },
    };
    RemoveAdminUserMutation.commit(mutation);
  }

  render() {
    const { match, viewer } = this.props;
    const user = get(viewer, 'adminUsers.edges[0].node', {});

    return (
      <div>
        <Helmet title={user.name} />
        <h1>{user.name}</h1>
        <UserForm
          key={user.id}
          match={match}
          viewer={viewer}
          onSubmit={this.handleSubmit}
          remove={this.remove}
        />
      </div>
    );
  }
}

export default createFragmentContainer(UserView, {
  viewer: graphql`
    fragment UserView_viewer on Admin {
      schemaTypes {
        name
        fields
      }
      adminRoles(first: 999) {
        edges {
          node {
            id
            name
          }
        }
      }
      adminUsers(first: 1, ids: $ids) {
        edges {
          node {
            id
            username
            firstname
            lastname
            email
            status
            remoteAccess
            roles {
              id
              name
            }
            permissions {
              id
              obj
              act
            }
            suppliers {
              importProducts
              supplierCodes
            }
          }
        }
      }
      suppliers
    }
  `,
});
