import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Affix, Button, Form, Input, Popconfirm, Select, Switch } from 'antd';
import { FormBase, formItemLayout, formItemLayoutWithOutLabel } from '~/components/form';
import Presence from '~/components/Presence';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Item: FormItem } = Form;
const { Option } = Select;

class UserForm extends FormBase {
  static propTypes = {
    match: PropTypes.shape({
    }),
    viewer: PropTypes.shape({
      adminUsers: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      adminRoles: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    remove: PropTypes.func,
  }

  static defaultProps = {
    match: {},
    remove: () => {},
  }

  constructor(props) {
    super(props);
    const roles = get(this.props.viewer, 'adminUsers.edges', []).map(({node}) => (get(node, "roles", [])));
    const rolesName = roles.length > 0 ? roles[0].map((role) => (role.name)) : [];

    this.formRef = React.createRef();
    this.state = {
      isBrandManager: rolesName.includes("Brand Manager"),
    };
  }

  handleImportProductsChange = (checked) => {
    if (checked) {
      this.formRef.current.setFieldsValue({ suppliers: [] });
    }
    this.forceUpdate();
  };

  handleSuppliersChange = (value) => {
    const hasSuppliers = value && value.length > 0;
    if (hasSuppliers) {
      this.formRef.current.setFieldsValue({ importProducts: false });
    }
    this.forceUpdate();
  };


  render() {
    const { match, viewer } = this.props;
    const user = get(viewer, 'adminUsers.edges[0].node', {});
    let roles = get(viewer, 'adminRoles.edges', []);
    const allSuppliers = get(viewer, 'suppliers', []);
    const adminSuppliers = allSuppliers.filter(supplier => get(user, 'suppliers.supplierCodes', []).includes(supplier.code));
    const permissions = get(user, 'permissions', []);
    const schemaTypes = get(viewer, 'schemaTypes', []);

    const { COUNTRY } = process.env;
    if (COUNTRY === 'NZ') {
      roles = roles.filter(({ node }) => node.name !== 'Brand Manager');
    }

    return (
      <Form ref={this.formRef} onFinish={(values) => { this.props.onSubmit(this.formRef.current, values); }}>

        <Affix>
          <div>
            <Presence match={match} disableButton={this.handleDisableBtn} />
            <Button type="primary" htmlType="submit" disabled={this.shouldDisableBtn()} style={{ marginRight: '10px' }}>Save</Button>
            {user.id && (
              <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={() => { this.props.remove(user); }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">Delete</Button>
              </Popconfirm>
            )}
          </div>
        </Affix>

        <FormItem
          name="id"
          initialValue={user.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="User Name"
          name="username"
          rules={[{ required: true, message: 'required' }]}
          initialValue={user.username}
        >
          <Input placeholder="User Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="First Name"
          name="firstname"
          rules={[{ required: true, message: 'required' }]}
          initialValue={user.firstname}
        >
          <Input placeholder="First Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Last Name"
          name="lastname"
          rules={[{ required: true, message: 'required' }]}
          initialValue={user.lastname}
        >
          <Input placeholder="Last Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Email"
          name="email"
          rules={[{ required: false, message: 'required' }]}
          initialValue={user.email}
        >
          <Input type="email" placeholder="Email" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Password"
          name="password"
          rules={[{ required: !user.id, message: 'required' }]}
        >
          <Input placeholder="password" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Status"
          name="status"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(user, 'status') ? 1 : 0}
        >
          <Select placeholder="Status">
            <Option value={1}>Enabled</Option>
            <Option value={0}>Disabled</Option>
          </Select>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Roles"
          name="roles"
          initialValue={get(user, 'roles', []).map(p => ({
            key: p.id,
            label: p.name,
          }))}
        >
          <Select
            placeholder="Select Roles"
            labelInValue
            mode="multiple"
            optionFilterProp="children"
            onChange={(values) => {
              const isBrandManager = values.map((item) => (item.label)).includes("Brand Manager")
              this.setState({isBrandManager})
            }}
          >
            {roles.map((edge) => {
              const r = edge.node;
              return <Option key={r.id} value={r.id}>{r.name}</Option>;
            })}
          </Select>
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Remote Access"
          name="remoteAccess"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(user, 'remoteAccess') ? 1 : 0}
          extra="Disabling will restrict user logins to company premises only."
        >
          <Select placeholder="Remote Access">
            <Option value={1}>Enabled</Option>
            <Option value={0}>Disabled</Option>
          </Select>
        </FormItem>

        {this.state.isBrandManager && (
        <FormItem
          {...formItemLayout}
          label="Suppliers"
          name="suppliers"
          initialValue={adminSuppliers.map(p => ({
            key: p.code,
            label: p.name,
          }))}
          rules={[
            ({ getFieldValue }) => ({
            required: !getFieldValue('importProducts'),
            message: 'Suppliers are required unless "Import Products" is on.',
            }),
          ]}
        >
          <Select
            placeholder="Select Suppliers"
            labelInValue
            mode="multiple"
            allowClear
            optionFilterProp="children"
            onChange={this.handleSuppliersChange}
            disabled={this.formRef.current?.getFieldValue('importProducts')}
          >
            {allSuppliers.map((r) => {
              return <Option key={r.code} value={r.code}>{`${r.name} (${r.code})`}</Option>;
            })}
          </Select>
        </FormItem>
        )}

        {this.state.isBrandManager && (
        <FormItem
          {...formItemLayout}
          label="Import Products"
          name='importProducts'
          valuePropName='checked'
          initialValue={get(user, 'suppliers.importProducts', false)}
        >
          <Switch
            onChange={this.handleImportProductsChange}
            disabled={
              this.formRef.current?.getFieldValue('suppliers')?.length > 0
            }
          />
        </FormItem>
        )}

        <Form.List
          name="permissions"
          initialValue={permissions}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }, index) => (
                <Form.Item
                  key={key}
                  label={index === 0 ? 'Permissions' : ''}
                  {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                >
                  <FormItem name={[name, "id"]} hidden>
                    <Input />
                  </FormItem>

                  <Input.Group compact style={{ display: 'flex', alignItems: 'center' }}>
                    <FormItem
                      name={[name, "obj"]}
                      rules={[{ required: true, message: 'required' }]}
                      style={{ width: '50%' }}
                    >
                      <Select
                        placeholder="Graphql Field"
                        options={schemaTypes.map(({ name: label, fields: options }) => ({
                          label,
                          options: options.map((f) => ({ label: f, value: f })),
                        }))}
                        showSearch
                        allowClear
                      />
                    </FormItem>

                    <FormItem
                      name={[name, "act"]}
                      rules={[{ required: true, message: 'required' }]}
                    >
                      <Select placeholder="Action">
                        <Option value="read">Read</Option>
                      </Select>
                    </FormItem>

                    <MinusCircleOutlined
                      style={{ cursor: 'pointer', marginBottom: '24px', paddingLeft: '5px' }}
                      onClick={() => remove(name)}
                    />
                  </Input.Group>
                </Form.Item>
              ))}

              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const permissionList = getFieldValue("permissions");

                  return (
                    <Form.Item
                      label={permissionList.length === 0 ? 'Permissions' : ''}
                      {...(permissionList.length === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    >
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '50%' }}
                        icon={<PlusOutlined />}
                      >
                        Add Permission
                      </Button>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    );
  }
}
export default UserForm;
