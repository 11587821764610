/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CarouselImageHistory_viewer$ref: FragmentReference;
declare export opaque type CarouselImageHistory_viewer$fragmentType: CarouselImageHistory_viewer$ref;
export type CarouselImageHistory_viewer = {|
  +id: string,
  +$refType: CarouselImageHistory_viewer$ref,
|};
export type CarouselImageHistory_viewer$data = CarouselImageHistory_viewer;
export type CarouselImageHistory_viewer$key = {
  +$data?: CarouselImageHistory_viewer$data,
  +$fragmentRefs: CarouselImageHistory_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CarouselImageHistory_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b8a7f4a25e70a3a30c11613468d97599';

module.exports = node;
