import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateCmsCarouselImageMutation($input: UpdateCmsCarouselImageInput!) {
    updateCmsCarouselImage(input:$input) {
      cmsCarouselImage {
        title
        link
        main1
        main2
        bg1
        bg2
        startDate
        endDate
        position
        status
        ...CarouselImageHistory_carouselImage
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };

