import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useFragment,
  useRefetchableFragment,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, groupBy as classify, isEqual, startCase } from 'lodash';
import moment from 'moment-timezone';
import { Button, Select, Table, TreeSelect, message } from 'antd';
import { DatePicker } from '~/components/form';
import { usePrevious } from '~/helper';
import SearchProduct from './SearchProduct';
import { TIMEZONE } from './helper';

const { RangePicker } = DatePicker;
const { SHOW_PARENT } = TreeSelect;

const entityName = 'By Min & Max Report';

export const selectFragment = graphql`
  fragment ByMinMaxReport_selectFragment_viewer on Admin {
    brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
      edges {
        node {
          id
          name
        }
      }
    }
    stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            state
            branchCode
          }
        }
      }
    suppliers
    ...SearchProduct_viewer
  }
`;

const reportFragment = graphql`
  fragment ByMinMaxReport_reportFragment_viewer on Admin
  @refetchable(queryName: "ByMinMaxReportRefetchQuery") {
    minMaxReport(count: 5000, filterBy: $filterBy, orderBy: $orderBy)
  }
`;

export const transformColumn = (column) => {
  const { children } = column;

  if (children) {
    return { ...column, children: children.map((c) => ({ dataIndex: c.key, title: startCase(c.key), ...c })) };
  }

  return { dataIndex: column.key, title: startCase(column.key), ...column }
};

const getTableColumns = () => {
  const sorter = () => { };
  const sortDirections = ["descend", "ascend"];

  return [
    { key: 'tag_no' },
    { key: 'name' },
    { key: 'max' },
    { key: 'min' },
    { key: 'qty_sold', sorter, sortDirections },
    { key: 'stock', sorter: (a, b) => a.stock - b.stock },
    { key: 'sold_over_min', title: "Sold/Min", render: (value) => value ? `${value}%` : null, sorter, sortDirections },
    { key: 'order_count', title: 'Total Orders', sorter, sortDirections },
    { key: 'sales_value', render: (value) => `$ ${value}`, sorter, sortDirections },
    {
      key: 'status',
      filters:
        [
          { text: 'enabled', value: 'enabled' },
          { text: 'disabled', value: 'disabled' },
          { text: '(null)', value: null },
        ],
      onFilter: (value, record) => record.status === value,
    },
    {
      key: 'clearance_promo',
      filters:
        [
          { text: 'Promo/Clearance Sale', value: 'Promo/Clearance Sale' },
          { text: 'Clearance Sale', value: 'Clearance Sale' },
          { text: 'Promo', value: 'Promo' },
          { text: '(null)', value: null },
        ],
      onFilter: (value, record) => record.clearance_promo === value,
    },
  ].map(transformColumn);
};

const getExpandedTableColumns = () => {
  return [
    { key: 'branch_name' },
    { key: 'branch_code' },
    { key: 'max' },
    { key: 'min' },
    { key: 'qty_sold', sorter: (a, b) => a.qty_sold - b.qty_sold },
    { key: 'stock', sorter: (a, b) => a.stock - b.stock },
    { key: 'sold_over_min', title: "Sold/Min", render: (value) => value ? `${value}%` : null, sorter: (a, b) => a.sold_over_min - b.sold_over_min },
    { key: 'order_count', title: 'Total Orders', sorter: (a, b) => a.order_count - b.order_count },
    { key: 'sales_value', render: (value) => `$ ${value}`, sorter: (a, b) => a.sales_value - b.sales_value },
    { key: 'state' },
  ].map(transformColumn);
};

export const LoadingTable = (props) => {
  return (
    <Table
      {...props}
      style={{ marginTop: '10px' }}
      dataSource={[]}
      size="small"
      loading
    />
  )
};

LoadingTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
};

LoadingTable.defaultProps = {
  columns: [],
};

const MinMaxTable = (props) => {
  const { tableRef, setOrderBy } = props;

  const [data, refetch] = useRefetchableFragment(reportFragment, props.viewer);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    tableRef.current = { data, refetch };
  }, [data, refetch]);

  const dataSource = get(data, 'minMaxReport', []);

  const columns = getTableColumns();

  const expandedColumns = getExpandedTableColumns();

  const expandedRowRender = (record) => {
    const expandedData = get(record, "breakdown", []);

    return (
      <Table
        columns={expandedColumns}
        dataSource={expandedData.map((item, index) => ({ ...item, key: index }))}
        pagination={false}
        size="small"
        rowKey={item => item.name}
        rowClassName="ant-alert-success"
      />
    );
  };

  return (
    <Table
      style={{ marginTop: '10px' }}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        defaultPageSize: 100000,
      }}
      onChange={(pagination, filters, sorter) => {
        const { order, field } = sorter;

        setOrderBy(() => {
          if (field === "stock") {
            return null;
          }

          if (order === "ascend") {
            return { field, direction: "asc" };
          } else if (order === "descend") {
            return { field, direction: "desc" };
          }
          return null;
        });
      }}
      size="small"
      scroll={{
        y: '70vh',
      }}
      expandable={{
        expandedRowRender,
        expandedRowKeys,
        onExpandedRowsChange: (keys) => setExpandedRowKeys(keys),
        rowExpandable: (record) => record.breakdown && record.breakdown.length > 0,
      }}
      rowClassName={(_record, index) => {
        return index % 2 === 1 ? "ant-alert-warning" : "";
      }}
    />
  )
};

MinMaxTable.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  tableRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }).isRequired,
  setOrderBy: PropTypes.func.isRequired,
};

const ByMinMaxReport = (props) => {
  const selectData = useFragment(selectFragment, props.viewer);

  const stores = get(selectData, 'stores.edges', []);
  const grouped = classify(stores, ({ node }) => node.state);
  const groupedKeys = Object.keys(grouped);

  const treeData = useMemo(() => {
    return [{
      value: 'all',
      title: 'All Stores',
      children: groupedKeys.sort().map((k) => ({
        value: k,
        title: k,
        children: grouped[k].map(({ node }) => ({ value: node.branchCode, title: `${node.name} (${node.branchCode})` }))
      })),
    }];
  }, [stores]);

  const rangePickerProps = useMemo(() => {
    const now = new Date();
    const today = moment(now).endOf('day');
    const last30 = moment(now).startOf('day').subtract(30, 'days');
    const last60 = moment(now).startOf('day').subtract(60, 'days');
    const last90 = moment(now).startOf('day').subtract(90, 'days');
    const last120 = moment(now).startOf('day').subtract(120, 'days');

    return {
      ranges: {
        'Last 30 Days': [last30, today],
        'Last 60 Days': [last60, today],
        'Last 90 Days': [last90, today],
        'Last 120 Days': [last120, today],
      }
    }
  }, []);

  const [rangePickerValue, setRangePickerValue] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [filters, setFilters] = useState({});
  const [tagNos, setTagNos] = useState([]);

  const tableRef = useRef(null);
  const prevOrderBy = usePrevious(orderBy);
  const prevFilters = usePrevious(filters);
  const [treeValue, setTreeValue] = useState([]);

  useEffect(() => {
    if (!isEqual(prevFilters, filters) || !isEqual(prevOrderBy, orderBy)) {
      let filterBy = null;
      const { refetch } = tableRef.current;
      const { created_at: createdAt, brand_name: brandName, supplier_code: supplierCode, tag_no: tagNumber } = filters;

      if (createdAt && (brandName || supplierCode || tagNumber)) {
        filterBy = Object.values(filters);
      }

      refetch({ filterBy, orderBy }, { fetchPolicy: 'store-and-network' });
    }
  }, [tableRef, prevFilters, filters, prevOrderBy, orderBy]);

  const onFilterChange = (key, values) => {
    const filter = { field: key, filter: values.join(), filterType: "text", type: "inRange" };

    if (values.length > 0) {
      setFilters((s) => ({ ...s, [key]: filter }));
    } else {
      setFilters((s) => {
        const { [key]: _, ...newState } = s;
        return newState;
      });
    }
  };

  const onDateChange = (dateRange) => {
    const key = "created_at";
    setRangePickerValue(dateRange);

    if (Array.isArray(dateRange)) {
      const startDate = moment(dateRange[0]).startOf('day').utc().format();
      const endDate = moment(dateRange[1]).endOf('day').utc().format();

      const filter = { field: key, filter: `${startDate},${endDate}`, filterType: "text", type: "inRange" };

      setFilters((s) => ({ ...s, [key]: filter }));
    } else {
      setFilters((s) => {
        const { [key]: _, ...newState } = s;
        return newState;
      });
    }
  };

  const onTreeChange = (value) => {
    const key = "branch_code";
    setTreeValue(value);

    if (['all', undefined].includes(value[0])) {
      setFilters((s) => {
        const { [key]: _, ...newState } = s;
        return newState;
      });
    } else {
      const branchCodeFilter = value.flatMap((v) => {
        if (groupedKeys.includes(v)) {
          return grouped[v].map(({ node }) => node.branchCode);
        }
        return v;
      });

      const filter = { field: key, filter: branchCodeFilter.join(","), filterType: "text", type: "inRange" };
      setFilters((s) => ({ ...s, [key]: filter }));
    }
  };

  const onDownload = () => {
    const brandFilter = get(filters, "brand_name.filter");
    const supplierFilter = get(filters, "supplier_code.filter");

    if (brandFilter || supplierFilter) {
      let f = Object.values(filters);
      f = JSON.stringify(f);

      const downloadUrl = `/api/report/by-min-max/download?filters=${encodeURIComponent(f)}`;

      window.open(downloadUrl, '_blank', 'noopener,noreferrer');
    } else {
      message.error("Please select brand(s) or supplier(s)")
    }
  };

  const setSearchProductText = (tags) => {
    setTagNos(tags);
    onFilterChange("tag_no", tags)
  };

  return (
    <div>
      <Helmet title={`${entityName} List`} />
      <h1>{entityName}</h1>

      <RangePicker
        {...rangePickerProps}
        value={rangePickerValue}
        onChange={onDateChange}
        allowClear
        timezone={TIMEZONE}
      />

      <Select
        mode="multiple"
        style={{ width: '50%', display: 'block', marginTop: '10px' }}
        placeholder="By Brand"
        options={get(selectData, 'brands.edges', []).map(({ node }) => ({ value: node.name, label: node.name }))}
        onChange={(values) => {onFilterChange("brand_name", values)}}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        allowClear
        showSearch
      />

      <TreeSelect
        style={{ width: '50%', display: 'block', marginTop: '10px' }}
        treeDefaultExpandedKeys={['all']}
        treeData={treeData}
        value={treeValue}
        onChange={onTreeChange}
        showCheckedStrategy={SHOW_PARENT}
        placeholder="By Stores"
        filterTreeNode={(input, treeNode) => treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        treeCheckable
        allowClear
      />

      <Select
        mode="tags"
        style={{ width: '50%', display: 'block', marginTop: '10px' }}
        placeholder="By Supplier"
        options={get(selectData, 'suppliers', []).map(({code, name}) => ({ value: code, label: `${name} (${code})` }))}
        onChange={(values) => {onFilterChange("supplier_code", values)}}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        tokenSeparators={[',', ' ']}
        allowClear
        showSearch
      />

      <SearchProduct viewer={selectData} tagNos={tagNos} setSearchText={setSearchProductText} />

      <Button style={{ marginTop: '10px' }} onClick={onDownload}>
        Download
      </Button>

      <h3>This table displays a sample of 5,000 records. To access the complete dataset, please press the Download button.</h3>

      <React.Suspense fallback={<LoadingTable columns={getTableColumns()} />}>
        <MinMaxTable
          tableRef={tableRef}
          viewer={props.viewer}
          setOrderBy={setOrderBy}
          rangePickerValue={rangePickerValue}
        />
      </React.Suspense>
    </div>
  )
};

ByMinMaxReport.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
};

export default ByMinMaxReport;
