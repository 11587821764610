import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation UpdateAdminUserMutation($input: UpdateAdminUserInput!) {
    updateAdminUser(input:$input) {
      adminUser {
        username
        firstname
        lastname
        email
        status
        remoteAccess
        roles {
          id
          name
        }
        permissions {
          id
          obj
          act
        }
        suppliers {
          importProducts
          supplierCodes
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
