/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddAdminUserInput = {|
  email?: ?string,
  firstname: string,
  importProducts?: ?boolean,
  lastname: string,
  password: string,
  permissions?: ?$ReadOnlyArray<?InputPermission>,
  remoteAccess?: ?boolean,
  roles: $ReadOnlyArray<?string>,
  status: boolean,
  suppliers?: ?$ReadOnlyArray<?string>,
  username: string,
|};
export type InputPermission = {|
  act?: ?string,
  id?: ?string,
  obj?: ?string,
|};
export type AddAdminUserMutationVariables = {|
  input: AddAdminUserInput
|};
export type AddAdminUserMutationResponse = {|
  +addAdminUser: ?{|
    +adminUserEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +username: ?string,
        +firstname: ?string,
        +lastname: ?string,
        +email: ?string,
        +status: ?boolean,
        +remoteAccess: ?boolean,
        +roles: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
        +permissions: ?$ReadOnlyArray<?{|
          +id: string,
          +obj: ?string,
          +act: ?string,
        |}>,
        +suppliers: ?{|
          +importProducts: ?boolean,
          +supplierCodes: ?$ReadOnlyArray<?string>,
        |},
      |},
    |}
  |}
|};
export type AddAdminUserMutation = {|
  variables: AddAdminUserMutationVariables,
  response: AddAdminUserMutationResponse,
|};
*/


/*
mutation AddAdminUserMutation(
  $input: AddAdminUserInput!
) {
  addAdminUser(input: $input) {
    adminUserEdge {
      cursor
      node {
        id
        username
        firstname
        lastname
        email
        status
        remoteAccess
        roles {
          id
          name
        }
        permissions {
          id
          obj
          act
        }
        suppliers {
          importProducts
          supplierCodes
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddAdminUserPayload",
    "kind": "LinkedField",
    "name": "addAdminUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminUserEdge",
        "kind": "LinkedField",
        "name": "adminUserEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminUser",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remoteAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminRole",
                "kind": "LinkedField",
                "name": "roles",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Permission",
                "kind": "LinkedField",
                "name": "permissions",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "obj",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "act",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SupplierPermission",
                "kind": "LinkedField",
                "name": "suppliers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "importProducts",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supplierCodes",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAdminUserMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAdminUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "03afb1b0078288da5efd170d94b93d0a",
    "id": null,
    "metadata": {},
    "name": "AddAdminUserMutation",
    "operationKind": "mutation",
    "text": "mutation AddAdminUserMutation(\n  $input: AddAdminUserInput!\n) {\n  addAdminUser(input: $input) {\n    adminUserEdge {\n      cursor\n      node {\n        id\n        username\n        firstname\n        lastname\n        email\n        status\n        remoteAccess\n        roles {\n          id\n          name\n        }\n        permissions {\n          id\n          obj\n          act\n        }\n        suppliers {\n          importProducts\n          supplierCodes\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f75b0286238def5f730bc05e99ca6499';

module.exports = node;
